import React from 'react';

// components
import HomeComp from '../../components/frontend-components/home';

const Home = () => {
    return (
        <>
            <HomeComp />
        </>
        
    )
}

export default Home;
