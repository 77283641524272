import React from 'react';

// components
import AirtimesComp from '../../components/frontend-components/airtimes-comp';

const Airtimes = () => {
    return (
        <>
            <AirtimesComp />
        </>
    )
}

export default Airtimes;
