import React from 'react'
import EmailVerificationComp from '../../components/auth-components/email-verification'

function EmailVerificationPage() {
  return (
    <>
        <EmailVerificationComp />
    </>
  )
}

export default EmailVerificationPage