import React from 'react';
import SignUpComp from '../../components/auth-components/sign-up';

function SignUP() {
  return (
    <>
        <SignUpComp />
    </>
  )
}

export default SignUP;