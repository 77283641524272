export const whatsAppUrl: string = `https://wa.me/+2348108816975`;
export type Faq = {
  question: string;
  answers: string[];
};
export const faqs: Faq[] = [
  {
    question: "How do I get started on the website?",
    answers: [
      "Visit  the website chinosexchange.con and click on sign up",
      "Put in your information as requested",
      "Verify your email address with the link sent to you email",
    ],
  },
  {
    question: "How do I get started on the website?",
    answers: [
      "Visit  the website chinosexchange.con and click on sign up",
      "Put in your information as requested",
      "Verify your email address with the link sent to you email",
    ],
  },
  {
    question: "How do I get started on the website?",
    answers: [
      "Visit  the website chinosexchange.con and click on sign up",
      "Put in your information as requested",
      "Verify your email address with the link sent to you email",
    ],
  },
];
