import React from 'react';

// components
import GiftCardsComp from '../../components/frontend-components/giftcards-comp';

const GiftCards = () => {
    return (
        <>
            <GiftCardsComp />
        </>
    )
}

export default GiftCards;
