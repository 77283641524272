import React from 'react';

// components
import CryptosComp from '../../components/frontend-components/cryptos-comp';

const Cryptos = () => {
    return (
        <>
            <CryptosComp />
        </>
    )
}

export default Cryptos;
