import React from 'react'
import SignInComp from '../../components/auth-components/sign-in';

function SignIn() {
  return (
    <>
        <SignInComp />
    </>
  )
}

export default SignIn;