import React from 'react';

// components
import ContactUsComp from '../../components/frontend-components/contact-us-comp';

const ContactUs = () => {
    return (
        <>
            <ContactUsComp />
        </>
    )
}

export default ContactUs;
