import React from 'react';

// components
import FaqComp from '../../components/frontend-components/faq-comp';

const Faq = () => {
    return (
        <>
            <FaqComp />
        </>
    )
}

export default Faq;
