import React from 'react';

// components
import AboutUsComp from '../../components/frontend-components/about-us-comp';

const AboutUs = () => {
    return (
        <>
            <AboutUsComp />
        </>
    )
}

export default AboutUs;
